import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { $fa } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const MedicalCheckupsList: RouteRecordRaw = {
	name: RouteKeys.MEDICAL_CHECKUPS_LIST,
	path: 'list',
	component: () => import('./list/medical-checkups-list.vue'),
	meta: {
		isForbidden: () => {
			const { hasMedicalCheckupsRetrieveFeature } = useFeatures();
			return computed(() => !hasMedicalCheckupsRetrieveFeature.value);
		}
	}
};

export const MedicalCheckupsServices: RouteRecordRaw = {
	name: RouteKeys.MEDICAL_CHECKUPS_SERVICES,
	path: 'services',
	component: () => import('./services/medical-checkups-services.vue'),
	meta: {
		isForbidden: () => {
			const { hasMedicalCheckupsSubscribeFeature } = useFeatures();
			return computed(() => !hasMedicalCheckupsSubscribeFeature.value);
		}
	}
};

export const MedicalCheckupsEmployee: RouteRecordRaw = {
	name: RouteKeys.MEDICAL_CHECKUPS_EMPLOYEE,
	path: 'employee/:employeeId',
	component: () => import('./employee/medical-checkups-employee.vue'),
	meta: {
		isForbidden: () => {
			const { hasMedicalCheckupsRetrieveFeature } = useFeatures();
			return computed(() => !hasMedicalCheckupsRetrieveFeature.value);
		}
	},
	props: route => ({
		employeeId: route.params.employeeId
	})
};

export const MedicalCheckupsRoute: RouteRecordRaw = {
	name: RouteKeys.MEDICAL_CHECKUPS,
	path: '/medical-checkups',
	components: {
		default: () => import('./medical-checkups.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { hasMedicalCheckupsSubscribeFeature, hasMedicalCheckupsRetrieveFeature } = useFeatures();
			return computed(() => !hasMedicalCheckupsRetrieveFeature.value && !hasMedicalCheckupsSubscribeFeature.value);
		},
		isNavEntry: true,
		label: 'admin.medical-checkups.navigation',
		icon: $fa('health'),
		requiresAuth: true,
		order: 3
	},
	children: [MedicalCheckupsServices, MedicalCheckupsList, MedicalCheckupsEmployee]
};
