import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $fa, Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const AgendaRoute: RouteRecordRaw = {
	name: RouteKeys.AGENDA,
	path: '/agenda',
	components: {
		default: () => import('./agenda.vue'),
		...defaultLayoutComponents
	},
	props: {
		default: route => ({ start: route.query.s, scope: route.query.gt })
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => isAdmin.value || Devices().isMobile);
		},

		label: 'agenda.navigation',
		icon: $fa('calendar'),
		isNavEntry: true,
		order: 2,
		requiresAuth: true
	}
};
