import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { $fa, Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const AdminLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_LEAVE_DAYS,
	path: '/admin-leave-days',
	components: {
		default: () => import('~/pages/leave-days/leave-days.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value),
		isNavEntry: true,
		label: 'leave_days.navigation',
		icon: $fa('list-bullets'),
		requiresAuth: true,
		order: 3
	}
};
