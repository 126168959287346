import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

import { ADMIN_BASE_URL, COMPANY_BASE_URL, ISODateString } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import {
	AdministeredEmployeeContractInformationDTO,
	EndContractReasonDTO,
	EndContractRequestDTO
} from './employee-contract-information.dto';
import {
	AdministeredEmployeeContractInformationPatchRequest,
	AdministeredEmployeeInformationDTO,
	AdministeredEmployeeInformationUpdateRequest
} from './employee-information.dto';
import {
	AdministeredEmployeePersonalInformationDTO,
	AdministeredEmployeePersonalInformationUpsertRequest,
	AdministeredEmployeePersonalInformationUpsertResponse
} from './employee-personal-information.dto';
import {
	AdministeredEmployeeRegistrationNumberUpdateRequest,
	AdministeredEmployeeRegistrationNumberUpdateResponse
} from './employee-registration-number.dto';
import {
	AdministeredEmployeeDTO,
	CheckEmailRequest,
	CheckEmailResponse,
	EmployeeDTO,
	EmployeeOffboardingDTO,
	EmployeeOnboardingDTO
} from './employees.dto';

const EMPLOYEE_API = 'employees';
const ADMIN_EMPLOYEE_API = 'administered-employees';
export function fetchCompanyEmployees$(
	companyId: number,
	period: ISODateString = DateTime.now().toISODate()
): Observable<Array<EmployeeDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${COMPANY_BASE_URL}/${companyId}/${EMPLOYEE_API}?period=${period}`;
	return http.get(URL);
}

export function fetchAdministeredEmployees$(companyId: number): Observable<Array<AdministeredEmployeeDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}`;
	return http.get(URL);
}

export function fetchAdministeredOnboardingEmployees$(companyId: number): Observable<Array<EmployeeOnboardingDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/onboarding`;
	return http.get(URL);
}

export function fetchAdministeredOffboardingEmployees$(companyId: number): Observable<Array<EmployeeOffboardingDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/offboarding`;
	return http.get(URL);
}

export function fetchAdministeredEmployeePersonalInformation$(
	companyId: number,
	employeeId: number
): Observable<AdministeredEmployeePersonalInformationDTO> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/personal-information`;
	return http.get(URL);
}

export function fetchAdministeredEmployeeInformation$(
	companyId: number,
	employeeId: number
): Observable<AdministeredEmployeeInformationDTO> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/information`;
	return http.get(URL);
}

export function fetchAdministeredEmployeeContractInformation$(
	companyId: number,
	employeeId: number,
	contractId: number
): Observable<AdministeredEmployeeContractInformationDTO> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/contract-information/${contractId}`;
	return http.get(URL);
}
// this API doesn't need a parameter "companyId" but we need to pass it because of a backend constraint
export function fetchEndContractReasons$(companyId: number): Observable<Array<EndContractReasonDTO>> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/offboarding/end-contract-reasons`;
	return http.get(URL);
}

export function requestEmployeeOffboarding$(
	companyId: number,
	employeeId: number,
	contractId: number,
	request: EndContractRequestDTO
): Observable<EndContractRequestDTO> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/offboarding/${contractId}/end-contract`;
	return http.post(URL, request);
}

export function createEmployee$(
	companyId: number,
	request: AdministeredEmployeePersonalInformationUpsertRequest
): Observable<AdministeredEmployeePersonalInformationUpsertResponse> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}`;
	return http.post(URL, request);
}

export function updateEmployeeInformation$(
	companyId: number,
	employeeId: number,
	request: AdministeredEmployeeInformationUpdateRequest
): Observable<void> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/information`;
	return http.put(URL, request);
}

export function updateEmployeeContractInformation$(
	companyId: number,
	employeeId: number,
	request: AdministeredEmployeeContractInformationPatchRequest
): Observable<void> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/contract-information/current`;
	return http.patch(URL, request);
}

export function updateEmployeeRegistrationNumber$(
	companyId: number,
	employeeId: number,
	request: AdministeredEmployeeRegistrationNumberUpdateRequest
): Observable<AdministeredEmployeeRegistrationNumberUpdateResponse> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/registration-number`;
	return http.put(URL, request);
}

export function updateEmployeePersonalInformation$(
	companyId: number,
	employeeId: number,
	request: AdministeredEmployeePersonalInformationUpsertRequest
): Observable<AdministeredEmployeePersonalInformationUpsertResponse> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/${employeeId}/personal-information`;
	return http.put(URL, request);
}

export function checkEmail$(companyId: number, request: CheckEmailRequest): Observable<CheckEmailResponse> {
	const { http } = useBackendHttpService();
	const URL = `${ADMIN_BASE_URL}/${companyId}/${ADMIN_EMPLOYEE_API}/check-email`;
	return http.post(URL, request);
}
