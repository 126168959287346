import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { $fa, Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const AdminAgendaRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AGENDA,
	path: '/admin-agenda',
	components: {
		default: () => import('~/pages/agenda/agenda.vue'),
		...defaultLayoutComponents
	},
	props: {
		default: route => ({ start: route.query.s, scope: route.query.gt })
	},
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value),
		isNavEntry: true,
		label: 'admin.navigation.agenda',
		icon: $fa('calendar'),
		requiresAuth: true,
		order: 2
	}
};
