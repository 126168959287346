import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { defineStore } from 'pinia';
import { PostHog, Survey } from 'posthog-js';
import { Observable } from 'rxjs';
import { Ref, ref } from 'vue';

import { Optional, debug, stripQueryParams, warn } from '@silae/helpers';
import { UserDTO } from '~/api';
import { EmailCampaignContext } from '~/domain';

import { TRACKING_STRIPPED_QUERY_PARAMS } from './tracking.constant';
import { TrackingEvent } from './tracking.domain';

type TrackingService = {
	clearUserContext: () => void;
	getSurveys$: () => Observable<Array<Survey>>;
	initAppInsights: (appInsights: ApplicationInsights) => void;
	initPostHog: (postHog: PostHog) => void;
	setUserContext: (user: UserDTO) => void;
	setUserProperties: (userProperties: Record<string, any>) => void;
	trackEmailCampaign: (context: EmailCampaignContext) => void;
	trackEvent: (event: TrackingEvent, customProperties?: Record<string, any>) => void;
	trackPageView: () => void;
};

/***
 	You should know that AdBlocker manages mostly to prevent tracking calls, and it can create some errors.
 */
export const useTrackingService = defineStore<'tracking-service', TrackingService>('tracking-service', () => {
	const _appInsights: Ref<Optional<ApplicationInsights>> = ref();
	const _postHog: Ref<Optional<PostHog>> = ref();

	const initPostHog = (postHog: PostHog) => (_postHog.value = postHog);

	const initAppInsights = (appInsight: ApplicationInsights) => (_appInsights.value = appInsight);

	const setUserContext = (user: UserDTO) => {
		const authenticatedUserId = user.login?.toLowerCase();
		_appInsights.value?.setAuthenticatedUserContext(authenticatedUserId);
		_postHog.value?.identify(authenticatedUserId, {
			email: authenticatedUserId,
			firstname: user.firstname,
			lastname: user.lastname,
			companies: user.companies
		});
	};

	const setUserProperties = (customProperties: Record<string, any>) => {
		_postHog.value?.setPersonProperties(customProperties);
	};

	const clearUserContext = () => {
		_appInsights.value?.clearAuthenticatedUserContext();
		_postHog.value?.reset();
	};

	function trackEmailCampaign(context: EmailCampaignContext): void {
		if (context == null) return;
		try {
			// clean up route prop
			delete context.route;
			trackEvent('Email Campaign Triggered', { ...context });
		} catch (ex: unknown) {
			warn('unable to decode email campaign context', { context });
		}
	}

	function getSurveys$(): Observable<Array<Survey>> {
		return new Observable(subscriber => {
			_postHog.value?.getActiveMatchingSurveys((surveys: Array<Survey>) => {
				subscriber.next(surveys);
				subscriber.complete();
			}, true);
		});
	}

	function trackEvent(event: TrackingEvent, props?: Record<string, any>): void {
		try {
			// remove JWT from URL sent to PostHog also
			const $current_url = stripQueryParams(window.location.href, TRACKING_STRIPPED_QUERY_PARAMS);
			_postHog.value?.capture(event, { ...props, $current_url });
		} catch (err: unknown) {
			// add blocker can prevent tracking or create an error
			// TODO we should probably catch this using Sentry
			debug('tracking failed');
		}
	}

	function trackPageView(): void {
		try {
			// trigger post hog auto capture as we're in an SPA
			// remove JWT from URL sent to PostHog
			// $current_url overrides the URL automatically sniffed by PostHog
			const $current_url = stripQueryParams(window.location.href, TRACKING_STRIPPED_QUERY_PARAMS);
			_postHog.value?.capture('$pageview', { $current_url });
		} catch (err: unknown) {
			// add blocker can prevent tracking or create an error
			// TODO we should probably catch this using Sentry
			debug('tracking failed');
		}
	}

	return {
		clearUserContext,
		getSurveys$,
		initAppInsights,
		initPostHog,
		setUserContext,
		setUserProperties,
		trackEmailCampaign,
		trackEvent,
		trackPageView
	};
});
