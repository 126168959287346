import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';

import { asQueryParams } from '../api.utils';
import { ADMIN_BASE_URL, ADMIN_V2_BASE_URL, PeriodRequest } from '../defs';
import { useBackendHttpService } from '../http';
import { LEAVE_DAYS_API } from './leave-days.api';
import {
	AdminLeaveDaysSearchRequest,
	LeaveDaysAdministrationDto,
	LeaveDaysBulkDecisionRequest,
	LeaveDaysCreationRequest,
	LeaveDaysDTO,
	LeaveDaysUpdateRequest
} from './leave-days.dto';

export function listAdministeredLeaveDaysInPeriod$(companyId: number, request: PeriodRequest): Observable<Array<LeaveDaysDTO>> {
	const { http } = useBackendHttpService();
	const params = asQueryParams(request);
	return http.get(`${ADMIN_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}?${params}`);
}

export function listAdministeredPayrollLeaveDays$(
	companyId: number,
	request: AdminLeaveDaysSearchRequest
): Observable<Array<LeaveDaysDTO>> {
	const { http } = useBackendHttpService();
	return http.post(`${ADMIN_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/payroll/_search`, request);
}

export function createLeaveDaysAsAdmin$(request: LeaveDaysCreationRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${request.companyId}/${LEAVE_DAYS_API}`, request);
}

export function modifyLeaveDaysAsAdmin$(request: LeaveDaysUpdateRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${request.companyId}/${LEAVE_DAYS_API}/${request.leaveDayUuid}/actions/edit`, request);
}

export function cancelLeaveDaysAsAdmin$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/cancel`, {
		comments: dto.comments
	});
}

export function rejectLeaveDaysAsAdmin$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/reject`, {
		comments: dto.comments
	});
}

export function approveLeaveDaysAsAdmin$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/approve`);
}

export function bulkRejectLeaveDaysAsAdmin$(companyId: number, request: LeaveDaysBulkDecisionRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/bulk-actions/reject`, request);
}

export function bulkApproveLeaveDaysAsAdmin$(companyId: number, request: LeaveDaysBulkDecisionRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${ADMIN_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/bulk-actions/approve`, request);
}

export function downloadFileAsAdmin$(companyId: number, fileId: string): Observable<AxiosResponse<string>> {
	const { http } = useBackendHttpService();
	return http.get(
		`${ADMIN_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/files/${fileId}`,
		{ responseType: 'arraybuffer' },
		(res: AxiosResponse) => res
	);
}
