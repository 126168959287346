import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { $fa } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const AutomaticEarnedWageAccessesRoute: RouteRecordRaw = {
	name: RouteKeys.AUTOMATIC_EARNED_WAGE_ACCESSES,
	path: '/automatic-earned-wage-accesses',
	components: {
		default: () => import('./automatic-earned-wage-accesses.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isEmployee } = storeToRefs(useRolesStore());
			return computed(() => !isEmployee.value || !useFeatures().hasAutomaticEarnedWageAccessesRequestFeature.value);
		},
		label: 'automatic-earned-wage-access.navigation',
		icon: $fa('payment-card-arrow'),
		isNavEntry: true,
		order: 5,
		requiresAuth: true
	}
};
