import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useHttpCache } from '@silae/composables';
import { Optional } from '@silae/helpers';
import { MedicalCheckupModuleStateDTO, listMedicalCheckups$, listServices$ } from '~/api';
import { MedicalCheckupDTO } from '~/api/medical-checkups/medical-checkups.dto';
import { useDateFormatter } from '~/composables/date-formatter.composables.ts';
import { PrettyMedicalCheckupDTO } from '~/domain';
import { prettyEmployeeName } from '~/utils';

import { Clearable } from '../store.domain.ts';

export type MedicalCheckupsStore = Clearable & {
	services: ComputedRef<Optional<Map<string, MedicalCheckupModuleStateDTO>>>;
	fetchServices: (companyId: number, invalidate?: boolean) => Observable<Map<string, MedicalCheckupModuleStateDTO>>;
	medicalCheckups: ComputedRef<Array<PrettyMedicalCheckupDTO>>;
	fetchMedicalCheckups: (companyId: number) => Observable<Array<MedicalCheckupDTO>>;
	medicalCheckupsForEmployee: ComputedRef<Array<PrettyMedicalCheckupDTO>>;
	fetchMedicalCheckupsForEmployee: (companyId: number, employeeId: number) => Observable<Array<MedicalCheckupDTO>>;
};

export const useMedicalCheckupsStore = defineStore<'medical-checkups-store', MedicalCheckupsStore>('medical-checkups-store', () => {
	const _services: Ref<Optional<Map<string, MedicalCheckupModuleStateDTO>>> = ref();
	const services: ComputedRef<Optional<Map<string, MedicalCheckupModuleStateDTO>>> = computed(() => _services.value);
	const { cache$, clearCache } = useHttpCache<number, Map<string, MedicalCheckupModuleStateDTO>>();

	const clear = () => {
		clearCache();
		medicalCheckupsClearCache();
		medicalCheckupsForEmployeeClearCache();
	};

	const fetchServices = (companyId: number, invalidate?: boolean): Observable<Map<string, MedicalCheckupModuleStateDTO>> => {
		if (invalidate) {
			clearCache();
		}

		return cache$(
			companyId,
			listServices$(companyId).pipe(
				map(serviceMapObject => {
					const mappedServices = new Map<string, MedicalCheckupModuleStateDTO>();
					// Somehow ... a map returned from the API is not a map but an Object !
					for (const [key, value] of Object.entries(serviceMapObject)) {
						mappedServices.set(key, {
							...value,
							id: key
						});
					}
					return mappedServices;
				}),
				tap(services => (_services.value = services))
			)
		);
	};

	const _medicalCheckups: Ref<Array<PrettyMedicalCheckupDTO>> = ref([]);
	const medicalCheckups: ComputedRef<Array<PrettyMedicalCheckupDTO>> = computed(() => _medicalCheckups.value);
	const { cache$: medicalCheckupsCache$, clearCache: medicalCheckupsClearCache } = useHttpCache<number, Array<PrettyMedicalCheckupDTO>>();
	const fetchMedicalCheckups = (companyId: number): Observable<Array<PrettyMedicalCheckupDTO>> => {
		return medicalCheckupsCache$(
			companyId,
			listMedicalCheckups$(companyId, true).pipe(
				map(medicalCheckups => {
					return medicalCheckups.map(medicalCheckup => {
						return convertToPrettyMedicalCheckup(medicalCheckup);
					});
				})
			)
		).pipe(
			tap(medicalCheckups => {
				_medicalCheckups.value = medicalCheckups;
			})
		);
	};

	const _medicalCheckupsForEmployee: Ref<Array<PrettyMedicalCheckupDTO>> = ref([]);
	const medicalCheckupsForEmployee: ComputedRef<Array<PrettyMedicalCheckupDTO>> = computed(() => _medicalCheckupsForEmployee.value);
	const { cache$: medicalCheckupsCacheForEmployee$, clearCache: medicalCheckupsForEmployeeClearCache } = useHttpCache<
		string,
		Array<PrettyMedicalCheckupDTO>
	>();
	const fetchMedicalCheckupsForEmployee = (companyId: number, employeeId: number): Observable<Array<PrettyMedicalCheckupDTO>> => {
		return medicalCheckupsCacheForEmployee$(
			`${companyId}_${employeeId}`,
			listMedicalCheckups$(companyId, false, employeeId).pipe(
				map(medicalCheckups => {
					return medicalCheckups.map(medicalCheckup => {
						return convertToPrettyMedicalCheckup(medicalCheckup);
					});
				})
			)
		).pipe(
			tap(cachedResult => {
				_medicalCheckupsForEmployee.value = cachedResult;
			})
		);
	};

	const { asTimeShort, asDurationTime, asDateShort } = useDateFormatter();
	const { t } = useI18n();

	function convertToPrettyMedicalCheckup(medicalCheckup: MedicalCheckupDTO): PrettyMedicalCheckupDTO {
		return {
			...medicalCheckup,
			employeeFullName: prettyEmployeeName(medicalCheckup.employee.firstName, medicalCheckup.employee.lastName),
			formattedDate: asDateShort(DateTime.fromISO(medicalCheckup.date)) ?? t(`common.fields.none-in-table`),
			formattedDurationTime:
				medicalCheckup.date && medicalCheckup.endTime
					? asDurationTime(
							DateTime.fromISO(medicalCheckup.date),
							DateTime.fromISO(medicalCheckup.date).set({
								hour: DateTime.fromFormat(medicalCheckup.endTime, 'HH:mm:ss').hour,
								minute: DateTime.fromFormat(medicalCheckup.endTime, 'HH:mm:ss').minute
							})
						)
					: t(`common.fields.none-in-table`),
			formattedConveningDate: asDateShort(DateTime.fromISO(medicalCheckup.conveningDate)) ?? t(`common.fields.none-in-table`),
			formattedConveningTime: asTimeShort(DateTime.fromISO(medicalCheckup.conveningDate)) ?? t(`common.fields.none-in-table`),
			formattedNextCheckupDate: asDateShort(DateTime.fromISO(medicalCheckup.nextDate)) ?? t(`common.fields.none-in-table`),
			medicalConclusionLabel:
				t(`medical-checkups.fields.medical-conclusion.${medicalCheckup.medicalConclusion.label}`) ??
				t(`common.fields.none-in-table`),
			medicalNatureLabel: t(`medical-checkups.fields.nature.${medicalCheckup.nature.label}`) ?? t(`common.fields.none-in-table`),
			medicalNatureDetails:
				t(`medical-checkups.fields.nature.details.${medicalCheckup.nature.details}`) ?? t(`common.fields.none-in-table`),
			medicalCenter: { ...medicalCheckup.medicalCenter, name: medicalCheckup.medicalCenter.name ?? t(`common.fields.none-in-table`) },
			medicalPractitionerTypeLabel:
				t(`medical-checkups.fields.practitioner-type.${medicalCheckup.medicalPractitionerType}`) ?? t(`common.fields.none-in-table`)
		};
	}

	return {
		clear,
		services,
		fetchServices,
		medicalCheckups,
		fetchMedicalCheckups,
		medicalCheckupsForEmployee,
		fetchMedicalCheckupsForEmployee
	};
});
