import { ComputedRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteLocationMatched, RouteRecordRaw, useRouter } from 'vue-router';

import { NavigationEntry } from '@silae/components';
import { useLocalBoolean } from '@silae/composables';

import { useGuardedRoutes } from './router.composables';

const appName = import.meta.env.MS_APP_NAME;
const { value: isCollapsed, toggle: toggleCollapse } = useLocalBoolean(`${appName}-is-collapsed`);

export { isCollapsed, toggleCollapse };

type NavigationEntriesOptions = {
	filterByNavEntry?: boolean;
	filterBySettingsNavEntry?: boolean;
};

export function useNavigationEntries(options: NavigationEntriesOptions = { filterByNavEntry: true }): {
	entries: ComputedRef<Array<NavigationEntry>>;
} {
	const { authorizedRoutes, authorizedRoutesByName } = useGuardedRoutes();

	const { t } = useI18n();
	const router = useRouter();
	const currentRoute = computed(() => router.currentRoute.value);
	const activeRoutes = computed(() => currentRoute.value?.matched ?? []);

	const isNavEntry = (route: RouteRecordRaw) =>
		route.meta?.requiresAuth &&
		((options.filterByNavEntry && route.meta?.isNavEntry) || (options.filterBySettingsNavEntry && route.meta?.isSettingsNavEntry));

	const isSubEntry = (route: RouteRecordRaw) =>
		route.meta?.requiresAuth && route.meta?.isSubEntry && authorizedRoutesByName.value.has(route.name);

	const asNavigationEntry = (route: RouteRecordRaw, activeRoutes: Array<RouteLocationMatched>): NavigationEntry => ({
		name: route.name as string,
		label: t(route?.meta?.label),
		icon: route.meta?.icon,
		active: activeRoutes.some(it => it.name === route.name),
		children: route?.children?.filter(isSubEntry)?.map(child => asNavigationEntry(child, activeRoutes)),
		to: route
	});

	const entries: ComputedRef<Array<NavigationEntry>> = computed(() =>
		authorizedRoutes.value
			.filter(isNavEntry)
			.toSorted((route, other) => route.meta?.order - other.meta?.order)
			.map(it => asNavigationEntry(it, activeRoutes.value))
	);

	return { entries };
}
