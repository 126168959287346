import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const TeamsRoute: RouteRecordRaw = {
	name: RouteKeys.CONFIGURATION_TEAMS,
	path: 'teams',
	components: {
		default: () => import('./teams.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasTeamsAndAccessAdminFeature.value),
		isSubEntry: true,
		label: 'admin.navigation.teams',
		requiresAuth: true,
		order: 70
	}
};
