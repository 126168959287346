import { AxiosResponse } from 'axios';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { asQueryParams } from '~/api/api.utils';
import { COMPANY_BASE_URL, COMPANY_V2_BASE_URL, PeriodRequest } from '~/api/defs';
import { useBackendHttpService } from '~/api/http';

import { LeaveDaysAdministrationDto, LeaveDaysCreationRequest, LeaveDaysDTO, LeaveDaysUpdateRequest } from './leave-days.dto';

export const LEAVE_DAYS_API = 'leave-days';

export function getLeaveDaysInPeriod$(companyId: number, request: PeriodRequest): Observable<Array<LeaveDaysDTO>> {
	const { http } = useBackendHttpService();
	const params = asQueryParams(request);
	return http.get(`${COMPANY_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}?${params}`);
}

export function listLeaveDaysInPeriod$(companyIds: Array<number>, request: PeriodRequest): Observable<Array<LeaveDaysDTO>> {
	return forkJoin(companyIds.map(companyId => getLeaveDaysInPeriod$(companyId, request))).pipe(
		map(data => data.reduce((dtos, it) => dtos.concat(it)))
	);
}

export function createLeaveDays$(request: LeaveDaysCreationRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${COMPANY_V2_BASE_URL}/${request.companyId}/${LEAVE_DAYS_API}`, request);
}

export function cancelLeaveDays$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${COMPANY_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/cancel`, {
		comments: dto.comments,
		leaveDayUuid: dto.leaveDayUuid
	});
}

export function modifyLeaveDays$(request: LeaveDaysUpdateRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${COMPANY_V2_BASE_URL}/${request.companyId}/${LEAVE_DAYS_API}/${request.leaveDayUuid}/actions/edit`, request);
}

export function deleteLeaveDays$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.delete(`${COMPANY_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}`);
}

export function downloadFile$(companyId: number, fileId: string): Observable<AxiosResponse<string>> {
	const { http } = useBackendHttpService();
	return http.get(
		`${COMPANY_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/files/${fileId}`,
		{ responseType: 'arraybuffer' },
		(res: AxiosResponse) => res
	);
}
