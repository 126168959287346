import { Observable, Subject, switchMap } from 'rxjs';
import { useI18n } from 'vue-i18n';

import { useDialogs } from '~/composables';

export function useSplitDaysComposables(): {
	showDialogSplitDaysToTriggerAction(actionLeaveDays$: Observable<void>): Observable<void>;
} {
	const { t } = useI18n();
	const { warning } = useDialogs();

	const showDialogSplitDaysToTriggerAction = (actionLeaveDays$: Observable<void>): Observable<void> => {
		const dialogActionClicked = new Subject<void>();

		warning({
			title: t('leave_days.split_days.dialog_title'),
			alertTitle: t('leave_days.split_days.dialog_alert_title'),
			alertText: t('leave_days.split_days.dialog_alert_text'),
			actionBtn: {
				label: t('common.buttons.confirm'),
				action: () => {
					dialogActionClicked.next();
					dialogActionClicked.complete();
				}
			},
			cancelAction: () => dialogActionClicked.complete(),
			closeBtnLabel: t('common.buttons.cancel'),
			persistent: true
		});

		return dialogActionClicked.asObservable().pipe(switchMap(() => actionLeaveDays$));
	};

	return {
		showDialogSplitDaysToTriggerAction
	};
}
