import { AxiosError } from 'axios';

import { GroupType } from './enums';

export const COMPANY_BASE_URL = '/v1/companies';
export const MANAGER_BASE_URL = '/v1/managed-companies';
export const ADMIN_BASE_URL = '/v1/admin-companies';

export const COMPANY_V2_BASE_URL = '/v2/companies';
export const MANAGER_V2_BASE_URL = '/v2/managed-companies';
export const ADMIN_V2_BASE_URL = '/v2/admin-companies';

export type ApiError<T = unknown> = {
	context?: T;
	error: string;
	errorCode: string;
	path: string;
	status: number;
	timestamp: number;
};

export type AxiosApiError<T = unknown> = AxiosError<ApiError<T>>;

export type PeriodRequest = {
	end?: string;
	groupType: GroupType;
	start?: string;
};

// format = "yyyy-MM-dd"
export type ISODateString = string;

// format = "yyyy-MM-ddThh:mm:ss"
export type ISODateTimeString = string;
