import { ComputedRef, Ref, computed, ref } from 'vue';

// We use a singleton ref here to avoid a pinia store for a simple boolean value
const _surveyTriggered: Ref<boolean> = ref(false);

export function useSurvey(): {
	surveyEnabled: ComputedRef<boolean>;
	triggerSurvey: () => void;
} {
	function triggerSurvey() {
		if (!_surveyTriggered.value) {
			_surveyTriggered.value = true;
		}
	}

	return {
		triggerSurvey,
		surveyEnabled: computed(() => _surveyTriggered.value)
	};
}
