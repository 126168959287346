import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { extractFilenameFromContentDispositionHeader } from '../api.utils.ts';
import { ADMIN_BASE_URL } from '../defs';
import { useBackendHttpService } from '../http';
import { FileContent, MedicalCheckupDTO, MedicalCheckupModuleStateDTO } from './medical-checkups.dto';

export function listServices$(companyId: number): Observable<Map<string, MedicalCheckupModuleStateDTO>> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/medical-checkups/services`);
}

export function activateService$(companyId: number, service: string): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${ADMIN_BASE_URL}/${companyId}/medical-checkups/services/${service}/activate`);
}

export function listMedicalCheckups$(
	companyId: number,
	onlyLastMedicalCheckup: boolean,
	employeeId?: number
): Observable<Array<MedicalCheckupDTO>> {
	const employeeIds = employeeId ? [employeeId] : [];
	const { http } = useBackendHttpService();
	return http.post(`${ADMIN_BASE_URL}/${companyId}/medical-checkups/_search`, {
		employeeIds: employeeIds,
		onlyLastMedicalCheckup: onlyLastMedicalCheckup
	});
}

export function downloadAttachmentFile$(companyId: number, medicalCheckup: MedicalCheckupDTO): Observable<FileContent> {
	const { http } = useBackendHttpService();
	return http
		.get(
			`${ADMIN_BASE_URL}/${companyId}/medical-checkups/${medicalCheckup.id}/attachment`,
			{ responseType: 'arraybuffer' },
			(res: AxiosResponse) => res
		)
		.pipe(
			map(axiosResponse => {
				const filename = extractFilenameFromContentDispositionHeader(axiosResponse);
				return {
					filename: filename,
					data: axiosResponse.data,
					format: filename.split('.').pop()
				};
			})
		);
}
