import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { $fa } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const PayslipsRoute: RouteRecordRaw = {
	name: RouteKeys.GED_PAYSLIPS,
	path: 'payslips',
	component: () => import('./payslips/payslips-list.vue'),
	meta: {
		isForbidden: () => computed(() => !useFeatures().hasPayslipsFeature.value)
	}
};

export const DocumentsRoute: RouteRecordRaw = {
	name: RouteKeys.GED_DOCUMENTS,
	path: 'documents',
	component: () => import('./documents/documents-list.vue')
};

export const GedRoute: RouteRecordRaw = {
	name: RouteKeys.GED,
	path: '/ged',
	components: {
		default: () => import('./ged.vue'),
		...defaultLayoutComponents
	},
	children: [PayslipsRoute, DocumentsRoute],
	redirect: () => (useFeatures().hasPayslipsFeature.value ? PayslipsRoute : DocumentsRoute),
	meta: {
		isForbidden: () => {
			const { isManager, isAdmin } = storeToRefs(useRolesStore());
			return computed(() => isManager.value || isAdmin.value);
		},
		label: 'GED.navigation',
		icon: $fa('folder'),
		isNavEntry: true,
		order: 4,
		requiresAuth: true
	}
};
