import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const AdminAutomaticEarnedWageAccessRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AUTOMATIC_EARNED_WAGE_ACCESS,
	path: '/admin-earned-wage-access',
	components: {
		default: () => import('./automatic-earned-wage-access.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasAutomaticEarnedWageAccessesAdminFeature.value),
		isSubEntry: true,
		label: 'admin.navigation.automatic-earned-wage-access',
		requiresAuth: true,
		order: 20
	}
};
