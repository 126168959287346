import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable, tap } from 'rxjs';
import { ComputedRef, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { ListEntry, mapById } from '@silae/helpers';
import { EmployeeOffboardingDTO, EndContractReasonDTO, fetchAdministeredOffboardingEmployees$, fetchEndContractReasons$ } from '~/api';

import { useCompaniesStore } from '../companies.store';
import { Clearable } from '../store.domain';

const singletonEndContractReasonsReferential = 'endContractReasons';

export type AdministeredOffboardingEmployeesStore = Clearable & {
	fetchAdministeredOffboardingEmployees$: (companyId: number, invalidateCache?: boolean) => Observable<Array<EmployeeOffboardingDTO>>;
	fetchEndContractReasons$: (companyId: number) => Observable<Array<EndContractReasonDTO>>;
	endContractReasons: ComputedRef<Array<EndContractReasonDTO>>;
	prettyEndContractReasons: ComputedRef<Array<ListEntry<string>>>;
	endContractReasonsById: ComputedRef<Map<string, EndContractReasonDTO>>;
};

export const useAdministeredOffboardingEmployeesStore = defineStore<
	'administered-offboarding-employee-store',
	AdministeredOffboardingEmployeesStore
>('administered-offboarding-employee-store', () => {
	const { companiesById } = storeToRefs(useCompaniesStore());
	const isAuthorized = (companyId: number): boolean => {
		const hasThisCompany = companiesById.value.has(companyId);
		return hasThisCompany ? companiesById.value.get(companyId).isAdmin : false;
	};

	// Employees offboarding
	const { cache$: cacheAdministeredOffboardingEmployees$, clearCache: clearAdministeredOffboardingEmployees } = useHttpCache<
		number,
		Array<EmployeeOffboardingDTO>
	>();
	const clear = () => {
		clearAdministeredOffboardingEmployees();
	};
	const fetchEmployees$ = (companyId: number, invalidateCache?: boolean): Observable<Array<EmployeeOffboardingDTO>> => {
		if (!isAuthorized(companyId)) return EMPTY;

		if (invalidateCache) clearAdministeredOffboardingEmployees();

		return cacheAdministeredOffboardingEmployees$(companyId, fetchAdministeredOffboardingEmployees$(companyId));
	};

	// End contract reasons
	const { cache$: cacheEndContractReasons$ } = useHttpCache<string, Array<EndContractReasonDTO>>();
	const _endContractReasons = ref<Array<EndContractReasonDTO>>([]);

	const _fetchEndContractReasons$ = (companyId: number): Observable<Array<EndContractReasonDTO>> => {
		if (!isAuthorized(companyId)) return EMPTY;

		return cacheEndContractReasons$(
			singletonEndContractReasonsReferential,
			fetchEndContractReasons$(companyId).pipe(tap(endContractReasons => (_endContractReasons.value = endContractReasons ?? [])))
		);
	};

	return {
		clear,
		fetchAdministeredOffboardingEmployees$: fetchEmployees$,
		fetchEndContractReasons$: _fetchEndContractReasons$,
		endContractReasons: computed(() => _endContractReasons.value),
		prettyEndContractReasons: computed(() => _endContractReasons.value.map(reason => ({ value: reason.id, label: reason.label }))),
		endContractReasonsById: computed(() => mapById(_endContractReasons.value))
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAdministeredOffboardingEmployeesStore, import.meta.hot));
