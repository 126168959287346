import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';

import { asQueryParams } from '../api.utils';
import { MANAGER_BASE_URL, MANAGER_V2_BASE_URL, PeriodRequest } from '../defs';
import { useBackendHttpService } from '../http';
import { LEAVE_DAYS_API } from './leave-days.api';
import {
	LeaveDaysAdministrationDto,
	LeaveDaysBulkDecisionRequest,
	LeaveDaysCreationRequest,
	LeaveDaysDTO,
	LeaveDaysUpdateRequest
} from './leave-days.dto';

export function listManagedLeaveDaysInPeriod$(companyIds: Array<number>, request: PeriodRequest): Observable<Array<LeaveDaysDTO>> {
	const { http } = useBackendHttpService();
	const params = asQueryParams(request);
	return http.get(`${MANAGER_V2_BASE_URL}/${LEAVE_DAYS_API}?companies=${companyIds.join(',')}&${params}`);
}

export function createLeaveDaysAsManager$(request: LeaveDaysCreationRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${request.companyId}/${LEAVE_DAYS_API}`, request);
}

export function modifyLeaveDaysAsManager$(request: LeaveDaysUpdateRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${request.companyId}/${LEAVE_DAYS_API}/${request.leaveDayUuid}/actions/edit`, request);
}

export function cancelLeaveDaysAsManager$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/cancel`, {
		comments: dto.comments
	});
}

export function rejectLeaveDaysAsManager$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/reject`, {
		comments: dto.comments
	});
}

export function approveLeaveDaysAsManager$(dto: LeaveDaysAdministrationDto): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${dto.companyId}/${LEAVE_DAYS_API}/${dto.leaveDayUuid}/actions/approve`);
}

export function bulkRejectLeaveDaysAsManager$(companyId: number, request: LeaveDaysBulkDecisionRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/bulk-actions/reject`, request);
}

export function bulkApproveLeaveDaysAsManager$(companyId: number, request: LeaveDaysBulkDecisionRequest): Observable<void> {
	const { http } = useBackendHttpService();

	return http.post(`${MANAGER_V2_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/bulk-actions/approve`, request);
}

export function downloadFileAsManager$(companyId: number, fileId: string): Observable<AxiosResponse<string>> {
	const { http } = useBackendHttpService();
	return http.get(
		`${MANAGER_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/files/${fileId}`,
		{ responseType: 'arraybuffer' },
		(res: AxiosResponse) => res
	);
}
