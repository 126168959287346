<template>
	<sk-card v-if="surveyDialog" class="survey-dialog pa-xs elevation-4" :title="$t('survey.NPS.question.label')">
		<div class="survey-dialog-rating">
			<div class="survey-dialog-rating-scores">
				<sk-tertiary-btn v-for="i in ratings" :key="i" :active="i === rating" icon :size="UiSize.SM" :text="i" @click="rate(i)" />
			</div>
			<div class="survey-dialog-rating-labels px-xs">
				<div class="text-neutral-text">{{ $t('survey.NPS.question.lower-bound-label') }}</div>
				<div class="text-neutral-text">{{ $t('survey.NPS.question.upper-bound-label') }}</div>
			</div>
		</div>

		<template #footer>
			<div class="survey-dialog-footer">
				<sk-tertiary-btn :text="$t('common.buttons.dismiss')" @click="dismiss" />
				<sk-primary-btn :disabled="rating == undefined" :text="$t('common.buttons.submit')" @click="submit" />
			</div>
		</template>
	</sk-card>
</template>

<style lang="scss" scoped>
.survey-dialog {
	position: absolute;
	z-index: 1312;
	bottom: var(--sk-spacing-md);
	left: var(--sk-spacing-md);

	&-rating {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: var(--sk-spacing-xs);

		&-scores,
		&-labels {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-footer {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}
</style>

<script lang="ts" setup>
import { SkCard, SkPrimaryBtn, SkTertiaryBtn } from '@silae/components';
import { UiSize } from '@silae/helpers';

import { useSurveyDialog } from './survey-dialog.composables.ts';

const { dismiss, rate, rating, ratings, submit, surveyDialog } = useSurveyDialog();
</script>
