import { LeaveDaysState } from '~/api';

enum ChipColors {
	POSITIVE = 'success',
	NEGATIVE = 'error',
	NEUTRAL = 'neutral',
	WARNING = 'warning',
	INFO = 'info',
	BLUE = 'cumulus90',
	EVERGREEN = 'evergreen90',
	PURPLE = 'purple90',
	PINK = 'pink90',
	TERRACOTTA = 'terracotta90',
	YELLOW = 'yellow90',
	GREEN = 'pomme80',
	EMERALD = 'emerald80'
}

function getStateColor(status: LeaveDaysState): ChipColors {
	switch (status) {
		case LeaveDaysState.APPROVED:
		case LeaveDaysState.INTEGRATED:
			return ChipColors.POSITIVE;
		case LeaveDaysState.PENDING:
			return ChipColors.NEUTRAL;
		case LeaveDaysState.PENDING_FOR_CANCELLATION:
		case LeaveDaysState.PENDING_FOR_MODIFICATION:
			return ChipColors.WARNING;
		case LeaveDaysState.REJECTED:
			return ChipColors.NEGATIVE;
		case LeaveDaysState.CANCELED:
		case LeaveDaysState.DELETED:
			return ChipColors.NEUTRAL;
	}
}

export { getStateColor, ChipColors };
