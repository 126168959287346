<template>
	<v-tooltip :disabled="isDisabledTooltip" location="top">
		<template #activator="{ props }">
			<sk-chip :color="getColor" :dot :text="getText" v-bind="props" />
		</template>

		<slot name="tooltip"> {{ tooltip }} </slot>
	</v-tooltip>
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { VTooltip } from 'vuetify/components';

import { SkChip } from '@silae/components';
import { LeaveDaysState } from '~/api';

import { ChipColors, getStateColor } from './simple-chip-colors';

const { t } = useI18n();
const props = withDefaults(
	defineProps<{
		text: string | LeaveDaysState;
		color?: ChipColors;
		dot?: boolean;
		tooltip?: string;
	}>(),
	{
		dot: true,
		color: ChipColors.NEUTRAL
	}
);
const { color, text, tooltip } = toRefs(props);

const isDisabledTooltip = computed(() => tooltip.value == null);

const isTextState = computed(() => {
	return text.value ? Object.values(LeaveDaysState).includes(text.value as LeaveDaysState) : false;
});

const getText = computed(() => {
	return isTextState.value ? t('common.status.' + text.value) : text.value;
});

const getColor = computed(() => {
	if (isTextState.value) {
		return getStateColor(text.value as LeaveDaysState);
	} else {
		return color.value;
	}
});
</script>
