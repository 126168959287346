import { Optional } from '@silae/helpers';

export function parseMoula(val: Optional<string>, decimalPlace: number): number {
	if (!val || val.length === 0) {
		return NaN;
	}

	if (!positiveMoulaRegex(decimalPlace).test(val)) {
		return NaN;
	}

	const numericalValue = val.replaceAll(',', '.');
	return parseFloat(numericalValue);
}

export const positiveMoulaRegex = (decimalPlace: number) => new RegExp(`^\\d*([,\\.]\\d{1,${decimalPlace}}|[,\\.])?$`);
