import { Optional } from '@silae/helpers';

/**
 *  Base 64 encoded URL have - instead of + and _ instead of /
 *  swap back
 *  */
export function decodeBase64URL(base64Url: string): Optional<string> {
	if (base64Url == undefined) return;
	try {
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		return decodeURIComponent(atob(base64));
	} catch {
		return;
	}
}

export const sanitizeXss = (value: string | undefined): string | undefined =>
	typeof value === 'string' ? value.replace(/['"<>\/=]/gm, encodeURIComponent) : value;
