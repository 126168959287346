import * as Sentry from '@sentry/vue';
import { App } from 'vue';

import { getRelease } from '~/../_build';
import { parseEnvVariable } from '~/utils';

/***
 we completely disabled tracing which allows to track front end and backend related transactions
 transaction tracking is extremely expensive
 */
export function setupSentry(app: App<Element>) {
	// compute transaction trace propagation target
	// const backendURL = import.meta.env.MS_BACKEND_URL;

	if (parseEnvVariable(import.meta.env.MS_ENABLE_SENTRY)) {
		Sentry.init({
			app,
			dsn: 'https://bb30a7aa6c0723a237e1ea830fa787f7@o21383.ingest.us.sentry.io/4507347093618688',
			integrations: [
				/* Sentry.browserTracingIntegration(), Sentry.replayIntegration() */
			],
			environment: import.meta.env.MS_ENVIRONMENT,
			release: getRelease(),
			// Performance Monitoring
			enableTracing: false,
			tracesSampleRate: 0 //  0% of the transactions captured between 0 - 1
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			// tracePropagationTargets: ['localhost', backendURL]
			// Session Replay
			// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			// replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		});
	}
}

export function initSentryUserContext(email: string, username: string): void {
	if (parseEnvVariable(import.meta.env.MS_ENABLE_SENTRY)) {
		Sentry.setUser({ email, username });
	}
}

export function clearSentryUserContext(): void {
	if (parseEnvVariable(import.meta.env.MS_ENABLE_SENTRY)) {
		Sentry.setUser(null);
	}
}
