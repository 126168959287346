import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const AdminEmployeeListRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_LIST,
	path: '',
	components: {
		default: () => import('./employee-list.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () =>
			computed(
				() =>
					Devices().isMobile ||
					!(
						useFeatures().hasAdministeredEmployeesAccessFeature.value ||
						useFeatures().hasAdministeredEmployeesRestrictedAccessFeature.value
					)
			),
		isSubEntry: true,
		label: 'admin.navigation.employees.list',
		requiresAuth: true,
		order: 3
	}
};
