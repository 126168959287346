<template>
	<v-app class="ms-app" :class="{ 'no-navigation': isCollapsed, dark: isDark }">
		<v-progress-linear v-if="isLoading" class="http-loader" color="secondary" data-test-id="http-loader" indeterminate />
		<router-view name="appbar" />
		<router-view name="nav" />

		<messages />
		<v-slide-y-reverse-transition mode="out-in">
			<survey-dialog v-if="surveyEnabled" />
		</v-slide-y-reverse-transition>

		<v-main class="ms-app-main">
			<router-view />
		</v-main>

		<help-menu-fab v-if="showHelpMenuFab" />
		<mobile-app-banner v-if="isAndroidOrIosDevice" />
	</v-app>
</template>

<style lang="scss" scoped>
.ms-app {
	background-color: var(--sk-background-hex);
	transition: background var(--sk-transition);
	overflow-x: hidden;

	.http-loader {
		position: absolute;
		z-index: 9999;
	}

	&-main {
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - var(--sk-app-bar-height));
		max-height: 100vh;
	}
}
</style>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { VApp, VMain, VProgressLinear, VSlideYReverseTransition } from 'vuetify/components';

import { useTheme } from '@silae/composables';
import { HelpMenuFab, Messages, MobileAppBanner, SurveyDialog } from '~/components';
import { isCollapsed } from '~/composables';
import { Devices, useVeeValidateI18n } from '~/plugins';
import { useLoadingService, useSurvey } from '~/services';
import { useAuthenticationStore } from '~/stores';

import {
	clearStateOnSignOut,
	initBackend,
	initErrorInterceptors,
	initLoadingService,
	initLocalStorageSynchronization,
	initLocale,
	initSessionLostRedirection,
	initStorageService,
	initTalentProvisioningUserStatus,
	initThemeSync,
	initUserTracking,
	useForbiddenRouteRedirect,
	useHealthCheck
} from './app.init';

initBackend();
initLocale();
initSessionLostRedirection();
initErrorInterceptors();
initLoadingService();
initStorageService();
useHealthCheck();
initUserTracking();
initThemeSync();
initLocalStorageSynchronization();
initTalentProvisioningUserStatus();

useForbiddenRouteRedirect();
clearStateOnSignOut();

const { isDark } = useTheme();

useVeeValidateI18n();
const { surveyEnabled } = useSurvey();

const isAndroidOrIosDevice = Devices().isIOS || Devices().isAndroid;

const { isAuthenticated } = storeToRefs(useAuthenticationStore());
const showHelpMenuFab = computed(() => isAuthenticated.value && isCollapsed.value);
const { isLoading } = storeToRefs(useLoadingService());
</script>
