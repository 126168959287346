import { ComputedRef, Ref, computed } from 'vue';

import { Optional } from '@silae/helpers';

import { Feature, LeaveDaysDTO } from '../api';
import { useFeatures } from './features.composables.ts';

/**
 * Provides a single boolean to know if the user is his own manager for the specified company (required) and leave days (optional).
 *
 * @param companyId required, if not provided, will return false
 * @param leaveDays optional
 */
export const useSelfManager = (
	companyId: Ref<Optional<number>>,
	leaveDays?: Ref<Optional<LeaveDaysDTO>>
): {
	isSelfManager: ComputedRef<boolean>;
} => {
	const { hasFeature } = useFeatures();

	const isSelfManager = computed(() => {
		if (!companyId.value) return false;
		return hasFeature(companyId.value, Feature.OWN_MANAGER) && (leaveDays?.value?.belongsToPrincipal ?? true);
	});

	return {
		isSelfManager
	};
};
