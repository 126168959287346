import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { Observable, of, switchMap } from 'rxjs';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Optional } from '@silae/helpers';
import {
	CONGES_PAYES_CODE,
	LeaveDaysCreationRequest,
	LeaveDaysDTO,
	LeaveDaysHistory,
	LeaveDaysUpdateRequest,
	createLeaveDays$,
	createLeaveDaysAsAdmin$,
	createLeaveDaysAsManager$,
	fetchSplittingDaysConfiguration$,
	modifyLeaveDays$,
	modifyLeaveDaysAsAdmin$,
	modifyLeaveDaysAsManager$
} from '~/api';
import { useSelfManager } from '~/composables/roles.composables.ts';
import { useCompanySelectionStore } from '~/stores/company-selection.store.ts';
import { useRolesStore } from '~/stores/roles.store.ts';
import { ISODateAsLocalDate, prettyEmployeeName, splitHoursAndMinutes } from '~/utils';

import { PrettyLeaveDays } from '../leave-days.domain';
import { useSplitDaysComposables } from './split-days.composables.ts';

export function useLeaveDays(): {
	getPrettyLabel: (label: string | undefined) => string;
	asPrettyLeaveDays: (leaveDays: LeaveDaysDTO) => PrettyLeaveDays;
	getPrettyLeaveDaysPeriod: (leaveDays: LeaveDaysDTO) => string;
	getPrettyLeaveDaysDuration: (leaveDays: LeaveDaysDTO) => string;
	getPrettyLeaveDaysHistoryPeriod: (history: LeaveDaysHistory) => string;
	getPrettyLeaveDaysHistoryDuration: (history: LeaveDaysHistory) => string;
} {
	const { t } = useI18n();

	// Must be in a composable because needs the composable useI18n
	const asPrettyLeaveDays = (leaveDays: LeaveDaysDTO): PrettyLeaveDays => {
		return {
			...leaveDays,
			selected: false,
			label: getPrettyLabel(leaveDays.label),
			employeeName: prettyEmployeeName(leaveDays.employeeFirstName, leaveDays.employeeLastName),
			prettyStart: getPrettyDate(leaveDays.start),
			prettyEnd: getPrettyDate(leaveDays.end),
			prettyDuration: getPrettyLeaveDaysDuration(leaveDays)
		};
	};

	const getPrettyLabel = (label: string | undefined): string => {
		return label ? label : t('common.fields.no_type');
	};

	const getPrettyDate = (ISO: string): Optional<string> => ISODateAsLocalDate(ISO, DateTime.DATE_SHORT);

	/**
	 * Ex: Journée, 0h45, ...
	 */
	const getPrettyLeaveDaysDuration = (leaveDays: LeaveDaysDTO): string => {
		if (leaveDays.isHalfDay) {
			return t('common.fields.half_day');
		}
		if (leaveDays.isHours) {
			const { hours, minutes } = splitHoursAndMinutes(leaveDays.hours);
			return t('common.fields.hours_minutes_value', { hours, minutes });
		}
		return t('common.fields.short_day');
	};

	/**
	 * Ex: du 13/02/2024 au 15/02/2024, le 17/02/2024 - 0h45, ...
	 */
	const getPrettyLeaveDaysPeriod = (leaveDays: LeaveDaysDTO): string => {
		if (leaveDays.isHalfDay) {
			return t('common.fields.period.half_day', { date: getPrettyDate(leaveDays.start) });
		}
		if (leaveDays.isHours) {
			return t('common.fields.period.hours', {
				date: getPrettyDate(leaveDays.start),
				duration: getPrettyLeaveDaysDuration(leaveDays)
			});
		}

		const start = DateTime.fromISO(leaveDays.start);
		const end = DateTime.fromISO(leaveDays.end);
		const diffInDays = end.diff(start, 'days').days;
		if (diffInDays >= 1) {
			return t('common.fields.period.many_days', {
				dateStart: getPrettyDate(leaveDays.start),
				dateEnd: getPrettyDate(leaveDays.end)
			});
		}
		return t('common.fields.period.one_day', { date: getPrettyDate(leaveDays.start) });
	};

	/**
	 * Ex: Journée, 0h45, ...
	 */
	const getPrettyLeaveDaysHistoryDuration = (history: LeaveDaysHistory): string => {
		if (history.isHalfDay) {
			return t('common.fields.half_day');
		}
		if (history.isHours) {
			const split = history.hours.split(':');
			const { hours, minutes } = { hours: split[0], minutes: split[1] };
			return t('common.fields.hours_minutes_value', { hours, minutes });
		}
		return t('common.fields.short_day');
	};

	/**
	 * Ex: du 13/02/2024 au 15/02/2024, le 17/02/2024 - 0h45, ...
	 */
	const getPrettyLeaveDaysHistoryPeriod = (history: LeaveDaysHistory): string => {
		if (history.isHalfDay) {
			return t('common.fields.period.half_day', { date: getPrettyDate(history.start) });
		}
		if (history.isHours) {
			return t('common.fields.period.hours', {
				date: getPrettyDate(history.start),
				duration: getPrettyLeaveDaysHistoryDuration(history)
			});
		}

		const start = DateTime.fromISO(history.start);
		const end = DateTime.fromISO(history.end);
		const diffInDays = end.diff(start, 'days').days;
		if (diffInDays >= 1) {
			return t('common.fields.period.many_days', {
				dateStart: getPrettyDate(history.start),
				dateEnd: getPrettyDate(history.end)
			});
		}
		return t('common.fields.period.one_day', { date: getPrettyDate(history.start) });
	};

	return {
		asPrettyLeaveDays,
		getPrettyLabel,
		getPrettyLeaveDaysPeriod,
		getPrettyLeaveDaysDuration,
		getPrettyLeaveDaysHistoryDuration,
		getPrettyLeaveDaysHistoryPeriod
	};
}

export function useSplitDaysConfirmationModal(leaveDaysTypeCode: ComputedRef<string>): {
	handleSplitDays(leaveDaysAction$: Observable<void>): Observable<void>;
} {
	const { isEmployee } = storeToRefs(useRolesStore());
	const { employeeCompanyId } = storeToRefs(useCompanySelectionStore());
	const { showDialogSplitDaysToTriggerAction } = useSplitDaysComposables();

	const needsToControlSplitDays = computed(() => isEmployee.value && leaveDaysTypeCode.value === CONGES_PAYES_CODE);

	const needsToShowSplitDaysModal = (): Observable<boolean> => {
		if (needsToControlSplitDays.value) {
			return fetchSplittingDaysConfiguration$(employeeCompanyId.value);
		}

		return of(false);
	};

	const handleSplitDays = (leaveDaysAction$: Observable<void>): Observable<void> => {
		return needsToShowSplitDaysModal().pipe(
			switchMap(needsToShow => {
				if (needsToShow) {
					return showDialogSplitDaysToTriggerAction(leaveDaysAction$);
				}
				return leaveDaysAction$;
			})
		);
	};

	return {
		handleSplitDays
	};
}

export function useLeaveDaysOperations(leaveDays?: Ref<Optional<LeaveDaysDTO>>): {
	createLeaveDays$: (request: LeaveDaysCreationRequest) => Observable<void>;
	modifyLeaveDays$: (request: LeaveDaysUpdateRequest) => Observable<void>;
} {
	const leaveDaysTypeCode = computed(() => leaveDays?.value?.typeCode ?? '');
	const { handleSplitDays } = useSplitDaysConfirmationModal(leaveDaysTypeCode);
	const { isManager, isAdmin } = storeToRefs(useRolesStore());

	const companyId: Ref<Optional<number>> = ref();
	const { isSelfManager } = useSelfManager(companyId, leaveDays);

	const _createLeaveDays$: (request: LeaveDaysCreationRequest) => Observable<void> = (request: LeaveDaysCreationRequest) => {
		companyId.value = request.companyId;

		let request$: Observable<void>;
		if (isAdmin.value) {
			request$ = createLeaveDaysAsAdmin$(request);
		} else if (isManager.value || isSelfManager.value) {
			request$ = createLeaveDaysAsManager$(request);
		} else {
			request$ = createLeaveDays$(request);
		}

		return handleSplitDays(request$);
	};

	const _modifyLeaveDays$: (request: LeaveDaysUpdateRequest) => Observable<void> = (request: LeaveDaysUpdateRequest) => {
		companyId.value = request.companyId;

		let request$: Observable<void>;
		if (isAdmin.value) {
			request$ = modifyLeaveDaysAsAdmin$(request);
		} else if (isManager.value || isSelfManager.value) {
			request$ = modifyLeaveDaysAsManager$(request);
		} else {
			request$ = modifyLeaveDays$(request);
		}

		return handleSplitDays(request$);
	};

	return {
		createLeaveDays$: _createLeaveDays$,
		modifyLeaveDays$: _modifyLeaveDays$
	};
}
