import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { COMPANY_BASE_URL } from '../../defs';
import { useBackendHttpService } from '../../http';
import { LEAVE_DAYS_API } from '../leave-days.api';

const SPLIT_DAYS_API = 'split-days';

export function fetchSplittingDaysConfiguration$(companyId: number): Observable<boolean> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/${LEAVE_DAYS_API}/${SPLIT_DAYS_API}`).pipe(map(r => r.displayNotification));
}
