import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { $fa, Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

import { AdminEmployeeLifecycleRoute } from './employee-lifecycle/employee-lifecycle.route';
import { AdminEmployeeListRoute } from './employee-list/employee-list.route';

export const AdminEmployeeManagementRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_MANAGEMENT,
	path: '/employees',
	components: {
		default: () => import('./employees.vue'),
		...defaultLayoutComponents
	},
	children: [AdminEmployeeListRoute, AdminEmployeeLifecycleRoute],
	redirect: _ => (useFeatures().hasAnyAdministeredEmployeesAccess.value ? AdminEmployeeListRoute : AdminEmployeeLifecycleRoute),
	meta: {
		isForbidden: () =>
			computed(
				() =>
					Devices().isMobile ||
					!(
						useFeatures().hasAdministeredEmployeesAccessFeature.value ||
						useFeatures().hasAdministeredEmployeesRestrictedAccessFeature.value ||
						useFeatures().hasOnboardingAdminFeature.value ||
						useFeatures().hasOffboardingAdminFeature.value
					)
			),
		isNavEntry: true,
		label: 'admin.navigation.employee-management',
		icon: $fa('user-group'),
		requiresAuth: true,
		order: 40
	}
};
