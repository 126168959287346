import { ConfigEnv } from 'vite';

import manifest from '../package.json';

export function getRelease(): string {
	return `my-silae@${manifest.version}`;
}

/***
 Strip HTML of some test attributes in production.
 Might be useful to add other attributes someday...
 */
const attributesToStrip = ['data-test-id'];

export function getVueOptions(env: ConfigEnv) {
	if (env.mode !== 'production') {
		return;
	}

	return {
		template: {
			compilerOptions: {
				nodeTransforms: [getDataStripper(attributesToStrip)]
			}
		}
	};
}

// thanks to https://github.com/vitejs/vite/issues/636
// Types come from @vue-compiler/core in case you need them
// getDataStripper(node: RootNode | TemplateChildNode) => void
function getDataStripper(attributesToStrip: Array<string>) {
	return (node: any) => {
		// NodeTypes.ELEMENT = 1
		if (node.type === 1) {
			attributesToStrip.forEach(attr => stripAttribute(node, attr));
		}
	};
}

function stripAttribute(node: any, attr: string): void {
	// NodeTypes.ATTRIBUTE = 6
	const attrIndex = node.props?.findIndex((prop: any) => prop?.type === 6 && prop?.name === attr);

	if (attrIndex > -1) {
		node.props.splice(attrIndex, 1);
	}
}
