import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const LoginsRoute: RouteRecordRaw = {
	name: RouteKeys.CONFIGURATION_LOGINS,
	path: 'logins',
	components: {
		default: () => import('./logins.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasTeamsAndAccessAdminFeature.value),
		isSubEntry: true,
		label: 'admin.navigation.logins',
		requiresAuth: true,
		order: 60
	}
};
