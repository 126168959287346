import { IconAliases } from 'vuetify';
import { fa } from 'vuetify/iconsets/fa-svg';

import { $fa } from '../icons';

const vuetifyOnlyIconAliases: Pick<
	IconAliases,
	| 'calendar'
	| 'cancel'
	| 'checkboxIndeterminate'
	| 'checkboxOff'
	| 'checkboxOn'
	| 'clear'
	| 'close'
	| 'complete'
	| 'delete'
	| 'delimiter'
	| 'dropdown'
	| 'edit'
	| 'error'
	| 'expand'
	| 'file'
	| 'first'
	| 'info'
	| 'last'
	| 'loading'
	| 'menu'
	| 'minus'
	| 'next'
	| 'plus'
	| 'prev'
	| 'radioOff'
	| 'radioOn'
	| 'ratingEmpty'
	| 'ratingFull'
	| 'ratingHalf'
	| 'sortAsc'
	| 'sortDesc'
	| 'subgroup'
	| 'success'
	| 'unfold'
	| 'warning'
> = {
	calendar: $fa('calendar'),
	cancel: $fa('arrow-cancel'),
	checkboxIndeterminate: $fa('square-minus'),
	checkboxOff: $fa('square'),
	checkboxOn: $fa('square-check'),
	clear: $fa('cross'),
	close: $fa('cross'),
	complete: $fa('check'),
	delete: $fa('basket'),
	delimiter: $fa('alien-monster'),
	dropdown: $fa('chevron-down'),
	edit: $fa('pen'),
	error: $fa('cross-circle-full'),
	expand: $fa('chevron-down'),
	file: $fa('file'),
	first: $fa('chevrons-left'),
	info: $fa('info-circle-full'),
	last: $fa('chevrons-right'),
	loading: $fa('alien-monster'),
	menu: $fa('menu'),
	minus: $fa('alien-monster'),
	next: $fa('chevron-right'),
	plus: $fa('alien-monster'),
	prev: $fa('chevron-left'),
	radioOff: $fa('circle'),
	radioOn: $fa('circle-dot'),
	ratingEmpty: $fa('alien-monster'),
	ratingFull: $fa('alien-monster'),
	ratingHalf: $fa('alien-monster'),
	sortAsc: $fa('sort-up'),
	sortDesc: $fa('sort-down'),
	subgroup: $fa('alien-monster'),
	success: $fa('check-circle-full'),
	unfold: $fa('chevron-down'),
	warning: $fa('exclamation-circle-full')
};

const customIconAliases: { [key: string]: string } = {
	add: $fa('plus-circle'),
	back: $fa('arrow-left'),
	download: $fa('arrow-down-line'),
	help: $fa('question-circle'),
	hide: $fa('eye-crossed'),
	import: $fa('import'),
	pending: $fa('hourglass'),
	refresh: $fa('arrow-refresh'),
	search: $fa('search'),
	send: $fa('paper-plane'),
	share: $fa('share'),
	show: $fa('eye')
};

export const iconAliases: { [key: string]: string } = {
	...(vuetifyOnlyIconAliases as { [key: string]: string }),
	...customIconAliases
};

export const vuetifyIcons = {
	defaultSet: 'fa',
	aliases: iconAliases,
	sets: {
		fa
	}
};
