export function isValidFrenchSSN(value: string): boolean {
	if (!value) {
		return true; // Allow empty values, control with 'required' rule if needed and not with this function!
	}

	// Replace potientialy Corsican department codes
	const SSN = value.slice(0, 15).replace(/2A/i, '19').replace(/2B/i, '18');

	if (!/^\d{15}$/.test(SSN)) {
		return false; // Check if the number is 15 digits long
	}

	const gender = parseInt(SSN.slice(0, 1), 10);
	if (gender !== 1 && gender !== 2) {
		return false; // Check gender (1 or 2)
	}

	const numWithoutKey = SSN.slice(0, 13); // Number without key
	const key = parseInt(SSN.slice(13, 15)); // Control key
	const expectedKey = 97 - (parseInt(numWithoutKey, 10) % 97);

	return key === expectedKey; // Check control key
}
