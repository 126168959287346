import { useStorage, watchImmediate } from '@vueuse/core';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { Survey } from 'posthog-js';
import { tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useObservable, useWritableBoolean } from '@silae/composables';
import { Optional } from '@silae/helpers';
import { useTrackingService } from '~/services';
import { useAuthenticationStore } from '~/stores';
import { getStorageKey } from '~/utils';

const ratings = Array.from({ length: 10 }).map((_, i) => String(i + 1));

export function useSurveyDialog(): {
	dismiss(): void;
	rate(score: string): void;
	rating: Ref<Optional<string>>;
	ratings: Array<string>;
	submit(): void;
	surveyDialog: Ref<boolean>;
} {
	const { trigger } = useObservable();
	const { isAuthenticated } = storeToRefs(useAuthenticationStore());
	const { trackEvent, getSurveys$ } = useTrackingService();
	const { surveyAllowed, disableSurvey } = useSurvey90DaysShusher();

	const rating: Ref<Optional<string>> = ref();

	const _survey: Ref<Optional<Survey>> = ref();

	const { value: surveyDialog, enable: displaySurveyDialog, disable: hideSurveyDialog } = useWritableBoolean(false);

	function dismiss() {
		if (_survey.value == undefined) {
			return;
		}

		trackEvent('survey dismissed', { $survey_id: _survey.value.id });
		disableSurvey();
		hideSurveyDialog();
	}

	function rate(value: string) {
		rating.value = value;
	}

	function submit() {
		if (_survey.value == undefined || rating.value == undefined) {
			return;
		}
		trackEvent('survey sent', {
			$survey_id: _survey.value.id,
			$survey_response: rating.value
		});
		disableSurvey();
		hideSurveyDialog();
	}

	function _getSurveys() {
		const fetch$ = getSurveys$().pipe(tap((data: Array<Survey>) => (_survey.value = data?.[0])));
		trigger(fetch$);
	}

	watchImmediate(
		() => ({
			isAuthenticated: isAuthenticated.value,
			surveyAllowed: surveyAllowed.value
		}),
		({ isAuthenticated, surveyAllowed }, former) => {
			if (isAuthenticated && !former?.isAuthenticated && surveyAllowed) {
				_getSurveys();
			} else if (!isAuthenticated && former?.isAuthenticated) {
				hideSurveyDialog();
			}
		}
	);

	function _showSurvey(id: string) {
		trackEvent('survey shown', { $survey_id: id });
		displaySurveyDialog();
	}

	watchImmediate(_survey, survey => {
		if (survey != undefined) {
			_showSurvey(survey.id);
		}
	});

	return {
		dismiss,
		rate,
		rating,
		ratings,
		submit,
		surveyDialog
	};
}

export function useSurvey90DaysShusher(): {
	surveyAllowed: ComputedRef<boolean>;
	disableSurvey: () => void;
} {
	// we prevent redundant and intrusive survey dialog by storing a TTL in local storage
	const disableSurveyTTL = useStorage<number>(getStorageKey('nps-survey-ttl'), 0);
	// allow survey if there is no TTL in local storage or if it has expired
	const surveyAllowed = computed(() => new Date(Number(disableSurveyTTL.value)) < new Date());

	function disableSurvey() {
		disableSurveyTTL.value = DateTime.now().plus({ day: 90 }).toMillis();
	}

	return {
		surveyAllowed,
		disableSurvey
	};
}
