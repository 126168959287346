import { AxiosResponse } from 'axios';

export function asQueryParams<T extends Record<string, any>>(obj: T): string {
	return Object.entries(obj)
		.filter(([_, value]) => value != null)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');
}

/**
 * This read an encoded jwt token and return the claim part.
 * We could also use a lib like `jwtdecode` that is surely more robust and give explicit error messages.
 * But, if this fail, it means `token` not a jwt token, and it means that there is a bigger problem somewhere
 */
export function parseJwt(token: string) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export function extractFilenameFromContentDispositionHeader(response: AxiosResponse) {
	const match = response.headers['content-disposition']?.match(/filename="?([^"]+)"?/);
	return match ? match[1] : null;
}
