import { FieldValidationMetaInfo, localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import { digits, email, length, max, min, numeric, regex, required, url } from '@vee-validate/rules';
import { DateTime } from 'luxon';
import { configure, defineRule } from 'vee-validate';
import { useI18n } from 'vue-i18n';

import { B2C_PASSWORD_REGEX, isValidBirthDate, parseMoula } from '~/utils';

import { isValidFrenchSSN } from './vee-valide.utils';

export type VeeValidateConfiguration = {
	locale: string;
};

const DEFAULT_CONFIG = {
	locale: 'fr'
};

type ValidationGenerateMessage = (ctx: FieldValidationMetaInfo) => string;

export const updateVeeValidateConfig = (config: VeeValidateConfiguration = DEFAULT_CONFIG) => {
	const conf = { ...DEFAULT_CONFIG, ...config };
	setLocale(conf?.locale);
};

export function useVeeValidateI18n() {
	const { t } = useI18n();

	/* localization for error messages, see: https://vee-validate.logaretm.com/v4/guide/i18n/ */
	/* french locale messages can be found here : https://www.npmjs.com/package/@vee-validate/i18n?activeTab=code */
	const simpleErrorMessageGenerator = localize({
		en: {
			...en,
			messages: {
				...en.messages,
				already_existing_email: 'This email address is already used for another employee',
				at_least_one_email: 'At least one email must be provided',
				b2c_password:
					'Password must be between 12 and 64 characters, containing at least one lowercase character, one uppercase character, one digit, and one symbol.',
				digits: 'The field must be an integer of 0:{length} digits',
				email: 'The field must be a valid email address',
				max: 'Up to 0:{length} characters',
				min: 'The field must contain at least 0:{length} characters',
				no_leading_or_trailing_spaces: 'The field cannot begin or end with spaces',
				password_confirmed: 'The password confirmation does not match the new password',
				password_different: 'The new password must be different from the current password',
				phone_number: 'The field must be a valid phone number',
				required: 'This field is required',
				url: 'The field must contain a valid url',
				validate_birthdate: 'The birthdate must be valid',
				french_ssn: 'The social security number must be valid'
			}
		},
		fr: {
			...fr,
			messages: {
				...fr.messages,
				already_existing_email: 'Cette adresse e-mail est déjà utilisée pour un autre salarié',
				at_least_one_email: 'Au moins un e-mail doit être renseigné',
				b2c_password:
					'Le mot de passe doit comporter entre 12 et 64 caractères, avec au moins une lettre minuscule, une lettre majuscule, un chiffre et un symbole.',
				digits: 'Le champ doit être un nombre entier de 0:{length} chiffres',
				email: 'Le champ doit être une adresse e-mail valide',
				max: '0:{length} caractères maximum',
				min: 'Le champ doit contenir au minimum 0:{length} caractères',
				no_leading_or_trailing_spaces: 'Le champ ne peut pas commencer ou se terminer par des espaces',
				password_confirmed: 'La confirmation du mot de passe ne correspond pas au nouveau mot de passe',
				password_different: 'Le nouveau mot de passe doit être différent du mot de passe actuel',
				phone_number: 'Le champ doit être un numéro de téléphone valide',
				required: 'Ce champ est requis',
				url: 'Le champ doit contenir une url valide',
				validate_birthdate: 'La date de naissance doit être valide',
				french_ssn: 'Le numéro de sécurité sociale doit être valide'
			}
		}
	});

	const complexErrorMessageGenerator: ValidationGenerateMessage = (ctx: FieldValidationMetaInfo) => {
		if (ctx.rule?.name === 'before') {
			const maxDate: Date = ctx.rule?.params?.[0];
			if (maxDate != null) {
				return t('common.rules.before', { date: DateTime.fromJSDate(maxDate).toLocaleString(DateTime.DATE_SHORT) });
			}
		}

		if (ctx.rule?.name === 'positive_moula') {
			const decimalPlace: number = ctx.rule?.params?.[0];
			if (decimalPlace != undefined) {
				return t('common.rules.positive_moula', { decimalPlace: decimalPlace });
			}
		}

		return simpleErrorMessageGenerator(ctx);
	};

	configure({
		generateMessage: complexErrorMessageGenerator
	});
}

export const setupVeeValidate = (config: VeeValidateConfiguration = DEFAULT_CONFIG) => {
	/* import rules from vee validate global validators, see: https://vee-validate.logaretm.com/v4/guide/global-validators/#vee-validaterules */
	/* to import more rules : https://vee-validate.logaretm.com/v4/guide/global-validators/#available-rules */
	defineRule('email', email);
	defineRule('digits', digits);
	defineRule('required', required);
	defineRule('min', min);
	defineRule('max', max);
	defineRule('url', url);
	defineRule('regex', regex);
	defineRule('numeric', numeric);
	defineRule('length', length);

	// Custom rules
	defineRule('no_leading_or_trailing_spaces', (value: string) => value.trim() === value);
	defineRule('password_different', (value: string, target: string) => String(value) !== String(target));
	defineRule('password_confirmed', (value: string, target: string) => String(value) === String(target));
	defineRule('b2c_password', (value: string) => B2C_PASSWORD_REGEX.test(value));
	defineRule('at_least_one_email', (value, [target]) => !!(value || target));
	defineRule('positive_moula', (value: string | number, [decimalPlaces]: [number]) => {
		const valueAsString = value?.toString() || '';
		return !valueAsString || parseMoula(valueAsString, decimalPlaces) > 0;
	});
	defineRule('after', (value: Date, candidate: Date) => value > candidate);
	defineRule('before', (value: Date, [candidate]: [Date]) => value < candidate);
	defineRule('validate_birthdate', (value: Date) => isValidBirthDate(value));
	defineRule('phone_number', (value: string) => !value || /^0\d{9}$/.test(value));
	defineRule('french_ssn', (value: string) => isValidFrenchSSN(value));

	updateVeeValidateConfig(config);
};
